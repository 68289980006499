import React, { useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";

import TextInput from "components/inputs/TextInput";
import PrimaryButton from "components/buttons/PrimaryButton";
import Stepper from "./components/Stepper";
import Alert from "components/Alert";
import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import useEditUserMutation from "hooks/mutations/EditUserMutation";

interface IStepProps {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  errors: { [x: string]: any };
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4>>;
  businessCategory: {
    value: string;
    label: string;
  }[];
  desc: string;
}
const Step: React.FunctionComponent<IStepProps> = ({
  register,
  handleSubmit,
  errors,
  setStep,
  businessCategory,
  desc,
}) => {
  // const navigate = useNavigate();
  const [error, setError] = useState("");
  const editUserMutation = useEditUserMutation();

  return (
    <>
      <img src={BiconomyWordmark} className="w-[50%] mx-auto" alt="" />
      <Stepper step={3} />
      <div className="flex justify-between items-baseline mb-10">
        <h1 className="font-medium text-2xl mx-auto text-foreground">
          Welcome! Let's get started...
        </h1>
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-medium text-foreground">Telegram Id *</label>
        <TextInput
          placeholder="biconauts"
          className="w-full"
          type="telegramId"
          registry={register("telegramId", {
            required: "Please enter the Telegram Id",
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /^[a-zA-Z0-9_]{1,15}$/,
              message: "Invalid telegram id",
            },
          })}
        ></TextInput>
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-medium text-foreground">
          Twitter Username *
        </label>
        <TextInput
          placeholder="biconomy"
          type="twitterUsername"
          registry={register("twitterUsername", {
            required: "Please enter your Twitter username",
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /^[a-zA-Z0-9_]{1,15}$/,
              message: "Invalid Twitter Username",
            },
          })}
        ></TextInput>
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-medium text-foreground">Discord Username</label>
        <TextInput
          placeholder="bico#1234"
          type="discordUsername"
          registry={register("discordUsername", {
            required: false,
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /.+#\d{4}/,
              message: "Invalid Discord Id",
            },
          })}
        ></TextInput>
      </div>
      {errors.telegramId && (
        <Alert color="error" message={errors.telegramId.message} />
      )}
      {errors.discordUsername && (
        <Alert color="error" message={errors.discordUsername.message} />
      )}
      {errors.twitterUsername && (
        <Alert color="error" message={errors.twitterUsername.message} />
      )}
      {error && <Alert color="error" message={error} />}
      <PrimaryButton
        className="w-full py-4 mt-4"
        onClick={handleSubmit(async (data) => {
          setError("");
          try {
            // const res = await fetch(
            //   `https://cors-anywhere.herokuapp.com/https://api.twitter.com/2/users/by/username/${data.twitterUsername}`,
            //   {
            //     method: "GET",
            //     headers: {
            //       Authorization: `Bearer ${process.env.REACT_APP_TWITTER_TOKEN}`,
            //     },
            //   }
            // );
            // const tdata = await res.json();
            // if (!tdata?.data?.id) {
            //   setError("Invalid Twitter Username");
            //   return;
            // }
            await editUserMutation.mutateAsync({
              // adminFullName: data.adminFullName,
              adminUserName: data.username,
              websiteUrl: data.url,
              businessCategory: businessCategory.map((data) => data.value),
              description: desc,
              alternateEmail: data.email,
              telegramId: data.telegramId,
              twitterId: data.twitterUsername,
              discordId: data.discordUsername,
            });
            setStep(4);
          } catch (err: any) {
            console.error("signUp error", err);
            setError(err.response.data.error || err.message);
          }
        })}
        type="submit"
      >
        <HiArrowRight className="w-5 h-auto" />
      </PrimaryButton>
    </>
  );
};

export default Step;
