import React, { useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";

import TextInput from "components/inputs/TextInput";
import PrimaryButton from "components/buttons/PrimaryButton";
import Stepper from "./components/Stepper";
import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import Alert from "components/Alert";
import useUserQuery from "hooks/useUserQuery";
import { Tooltip } from "components/Tooltip";

interface IStepProps {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  errors: { [x: string]: any };
  setName: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4>>;
}

const Step: React.FC<IStepProps> = ({
  register,
  handleSubmit,
  errors,
  setName,
  setStep,
}) => {
  const [error, setError] = useState("");
  const userQuery = useUserQuery();

  return (
    <>
      <img src={BiconomyWordmark} className="w-[50%] mx-auto" alt="" />
      <Stepper step={1} />
      <div className="flex justify-between items-baseline mb-10">
        <h1 className="font-medium text-2xl mx-auto text-foreground">
          Welcome! Let's get started...
        </h1>
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-medium text-foreground">
          <Tooltip message="Email that would help us contact buisness / finance team">
            Alternate email
            <img src="/icons/info.svg" className="w-3" alt="" />
          </Tooltip>
        </label>
        <TextInput
          placeholder="Email"
          className="w-full"
          type="email"
          registry={register("email", {
            required: "Please enter your email address",
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Invalid email address",
            },
          })}
        ></TextInput>
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-medium text-foreground">Username</label>
        <TextInput
          placeholder="Your username"
          type="username"
          registry={register("username", { required: true })}
        ></TextInput>
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-medium text-foreground">Website URL</label>
        <TextInput
          placeholder="Your dapp URL"
          type="URL"
          registry={register("url", {
            required: "Please enter your URL address",
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
              message: "Invalid URL address",
            },
          })}
        ></TextInput>
      </div>
      {errors.email && <Alert color="error" message={errors.email.message} />}
      {errors.username && <Alert color="error" message="Username is empty" />}
      {errors.url && <Alert color="error" message={errors.url.message} />}
      {error && <Alert color="error" message={error} />}
      <PrimaryButton
        className="w-full py-4 mt-4"
        onClick={handleSubmit(async (data) => {
          setError("");
          if (data.email === userQuery.data?.email) {
            setError("Enter another (alternate) email address");
            return;
          }
          try {
            setName(data.username);
            setStep(2);
          } catch (err: any) {
            console.error("error", err);
            setError(err.response.data.error || err.message);
          }
        })}
        type="submit"
      >
        <HiArrowRight className="w-5 h-auto" />
      </PrimaryButton>
    </>
  );
};

export default Step;
