export const allowedNetworks = [
  {
    title: "Ethereum Mainnet",
    id: "1",
    currency: "ETH",
    url: "https://etherscan.io/tx/",
  },
  {
    title: "Ethereum Ropsten",
    id: "3",
    currency: "ETH",
    url: "https://ropsten.etherscan.io/tx/",
  },
  {
    title: "Ethereum Kovan",
    id: "42",
    currency: "ETH",
    url: "https://kovan.etherscan.io/tx/",
  },
  {
    title: "Ethereum Rinkeby",
    id: "4",
    currency: "ETH",
    url: "https://rinkeby.etherscan.io/tx/",
  },
  {
    title: "Ethereum Goerli",
    id: "5",
    currency: "ETH",
    url: "https://goerli.etherscan.io/tx/",
  },
  {
    title: "Polygon Mainnet",
    id: "137",
    currency: "MATIC",
    url: "https://polygonscan.com/tx/",
  },
  {
    title: "Polygon Testnet",
    id: "80001",
    currency: "MATIC",
    url: "https://mumbai.polygonscan.com/tx/",
  },

  {
    title: "Gnosis Chain Mainnet",
    id: "100",
    currency: "GNO",
    url: "https://blockscout.com/xdai/mainnet/tx/",
  },
  {
    title: "BSC Mainnet",
    id: "56",
    currency: "BNB",
    url: "https://bscscan.com/tx/",
  },
  {
    title: "BSC Testnet",
    id: "97",
    currency: "BNB",
    url: "https://testnet.bscscan.com/tx/",
  },
  {
    title: "Moonbeam Mainnet",
    id: "1284",
    currency: "GLMR",
    url: "https://moonscan.io/tx/",
  },
  {
    title: "Moonriver Mainnet",
    id: "1285",
    currency: "MOVR",
    url: "https://moonriver.moonscan.io/tx/",
  },
  {
    title: "Moonbeam Testnet",
    id: "1287",
    currency: "GLMR",
    url: "https://moonbase-blockscout.testnet.moonbeam.network/tx/",
  },
  {
    title: "Arbitrum Mainnet",
    id: "42161",
    currency: "ETH",
    url: "https://arbiscan.io/tx/",
  },
  {
    title: "Arbitrum Testnet",
    id: "421611",
    currency: "ETH",
    url: "https://testnet.arbiscan.io/tx/",
  },
  {
    title: "Avalanche Testnet C-Chain",
    id: "43113",
    currency: "AVAX",
    url: "https://testnet.snowtrace.io/tx/",
  },
  {
    title: "Avalanche Mainnet C-Chain",
    id: "43114",
    currency: "AVAX",
    url: "https://snowtrace.io/tx/",
  },
  {
    title: "Fantom Mainnet",
    id: "250",
    currency: "FTM",
    url: "https://ftmscan.com/tx/",
  },
  {
    title: "Fantom Testnet",
    id: "4002",
    currency: "FTM",
    url: "https://testnet.ftmscan.com/tx/",
  },
  {
    title: "Optimism Testnet",
    id: "420",
    currency: "ETH",
    url: "https://blockscout.com/optimism/goerli",
  },
];

export const isTestnet = (networkId: string) => {
  const testent = [
    "3",
    "42",
    "4",
    "5",
    "80001",
    "97",
    "1287",
    "421611",
    "43113",
    "4002",
  ];
  if (testent.includes(networkId)) return "Testnet";
  return "Mainnet";
};

export const allowedNetworksStaging = [
  { title: "Ethereum Kovan", id: "42", currency: "ETH" },
  { title: "Polygon Testnet", id: "80001", currency: "MATIC" },
];
export const getNetworkDetail = (
  networkId: string,
  detail: "title" | "id" | "currency" | "url"
) => {
  // eslint-disable-next-line eqeqeq
  const ind = allowedNetworks.findIndex((network) => network.id == networkId);
  if (ind === -1) return "Unknown";
  return allowedNetworks[ind][detail];
};

export const networkIdToNetworkName = {
  "1": "Ethereum Mainnet",
  "3": "Ropsten Testnet",
  "5": "Goerli Testnet",
  "42": "Kovan TestNet",
  "56": "BSC Mainnet",
  "100": "Gnosis Chain Mainnet",
  "137": "Polygon Mainnet",
  "80001": "Polygon Mumbai Testnet",
  "421611": "Arbitrum Testnet",
  "42161": "Arbitrum Mainnet",
  "1285": "Moonriver Mainnet",
  "250": "Fantom Mainnet",
  "4002": "Fantom Testnet",
  "1287": "Moonbeam Testnet",
  "1284": "Moonbeam Mainnet",
  "43113": "Avalanche Testnet C-Chain",
  "43114": "Avalanche Mainnet C-Chain",
};

export const getExplorerURL = (networkId: number, txHash: string) => {
  return `${explorerURLMap}/tx/${txHash}`;
};
export const explorerURLMap = {
  1: "https://etherscan.io/tx/",
  3: "https://ropsten.etherscan.io/tx/",
  4: "https://rinkeby.etherscan.io/tx/",
  5: "https://goerli.etherscan.io/tx/",
  42: "https://kovan.etherscan.io/tx/",
  56: "https://bscscan.com/tx/",
  100: "https://blockscout.com/xdai/mainnet/tx/",
  137: "https://polygonscan.com/tx/",
  80001: "https://mumbai.polygonscan.com/tx/",
  97: "https://testnet.bscscan.com/tx/",
  43114: "https://snowtrace.io/tx/",
  43113: "https://testnet.snowtrace.io/tx/",
  421611: "https://testnet.arbiscan.io/tx/",
  42161: "https://arbiscan.io/tx/",
  1284: "https://moonscan.io/tx/",
  250: "https://ftmscan.com/tx/",
  4002: "https://testnet.ftmscan.com/tx/",
  1285: "https://moonriver.moonscan.io/tx/",
  1287: "https://moonbase-blockscout.testnet.moonbeam.network/tx/",
};

export type networkType =
  | "1"
  | "3"
  | "4"
  | "5"
  | "42"
  | "137"
  | "80001"
  | "100"
  | "56"
  | "97"
  | "1284"
  | "1285"
  | "1287"
  | "42161"
  | "421611"
  | "43113"
  | "43114"
  | "250"
  | "4002";

export const allowedCondtionalNetworks = [
  {
    title: "Ethereum Mainnet",
    id: "1",
    currency: "ETH",
    url: "https://etherscan.io/tx/",
  },
  {
    title: "Ethereum Rinkeby",
    id: "4",
    currency: "ETH",
    url: "https://rinkeby.etherscan.io/tx/",
  },
  {
    title: "Ethereum Goerli",
    id: "5",
    currency: "ETH",
    url: "https://goerli.etherscan.io/tx/",
  },
  {
    title: "Polygon Mainnet",
    id: "137",
    currency: "MATIC",
    url: "https://polygonscan.com/tx/",
  },
  {
    title: "Polygon Testnet",
    id: "80001",
    currency: "MATIC",
    url: "https://mumbai.polygonscan.com/tx/",
  },
];
